class FooterNavigation {
  constructor(el) {
    el.addEventListener('click', ev => this.handleClick(ev));
  }

  handleClick(ev) {
    if (ev.target.closest('.toggle-child-nodes')) {
      ev.preventDefault();

      const anchor = ev.target.closest('a');
      if (anchor.nextElementSibling) {
        anchor.classList.toggle('is-open');
        anchor.nextElementSibling.classList.toggle('hidden');
      }
    }
  }
}

export default function (selector = '.footer-navigation') {
  document.querySelectorAll(selector).forEach(element => {
    new FooterNavigation(element);
  });
}
