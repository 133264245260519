import Flickity from 'flickity';
import _ from 'lodash';

const carouselContainers = document.querySelectorAll('[data-carousel-container]');
const carousel = () => {
  for (let i = 0; i < carouselContainers.length; i++) {
    const carousel = carouselContainers[i].querySelectorAll('[data-carousel]')[0];
    if (!carousel) {
      console.error('No carousel found in container:', carouselContainers[i]);
      continue;
    }
    const carouselBounding = carousel.getBoundingClientRect();
    let carouselAmount = carousel.getAttribute('data-carousel');
    const carouselItems = carousel.querySelectorAll('[data-carousel-item]');
    const carouselPrevFunction = () => {
      if (flkty.selectedIndex > 0) {
        flkty.previous(true);
      }
    }
    const carouselNextFunction = () => {
      if (flkty.selectedIndex <= flkty.slides.length - carouselAmount) {
        flkty.next(true);
      }
    }
    const isInCarousel = (elem) => {
      const bounding = elem.getBoundingClientRect();
      if (bounding.left < carouselBounding.left - 15) {
        return 'prev'
      } else if (bounding.right > (carouselBounding.left + carouselBounding.width)) {
        return 'next'
      } else {
        return 'inside';
      }
    };
    const applyClasses = () => {
      let calculateCarouselAmount = 0;
      for (let j = 0; j < carouselItems.length; j++) {
        if (isInCarousel(carouselItems[j]) === 'prev') {
          carouselItems[j].classList.add("opacity-40");
          carouselItems[j].querySelectorAll('a')[0].classList.add("pointer-events-none");
          carouselItems[j].addEventListener('click', carouselPrevFunction);
        } else if (isInCarousel(carouselItems[j]) === 'next') {
          carouselItems[j].classList.add("opacity-40");
          carouselItems[j].querySelectorAll('a')[0].classList.add("pointer-events-none");
          carouselItems[j].addEventListener('click', carouselNextFunction);
        } else {
          calculateCarouselAmount += 1;
          carouselItems[j].classList.remove("opacity-40");
          carouselItems[j].querySelectorAll('a')[0].classList.remove("pointer-events-none");
          carouselItems[j].removeEventListener('click', carouselPrevFunction);
          carouselItems[j].removeEventListener('click', carouselNextFunction);
        }
      };
      carouselAmount = calculateCarouselAmount;
    }

    const flkty = new Flickity(carousel, {
      pageDots: false,
      prevNextButtons: false,
      cellAlign: 'left',
      contain: true,
      touchVerticalScroll: false,
      draggable: true,
      setGallerySize: true,
      resize: true,
      on: {
        ready: function () {
          applyClasses();
        }
      }
    });

    flkty.on('scroll', function (index) {
      applyClasses();
    });
  };
}

if (carouselContainers.length > 0) {
  carousel();
  window.addEventListener("resize", function () {
    carousel();
  });
  // Load Resize Hack
  window.addEventListener('load', () => {
    const sliders = Array.from(document.querySelectorAll('[data-carousel-container]'));
    sliders.forEach((slider) => {
      const flkty = Flickity.data(slider.querySelector('[data-carousel]'));
      if (flkty) {
        flkty.resize();
      } else {
        console.error('No Flickity instance found for slider:', slider);
      }
    });
  });
}
