var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "fadeIn", mode: "in-out" } }, [
    _c(
      "li",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.isLoading,
            expression: "!isLoading"
          }
        ],
        staticClass:
          "mb-4 pb-4 flex flex-wrap flex-col xxl:flex-row border-b border-1 border-solid border-grey-light"
      },
      [
        _c("div", { staticClass: "w-full" }, [
          _c("div", { staticClass: "flex flex-col lg:flex-row -mx-3" }, [
            _c(
              "div",
              { staticClass: "flex flex-row lg:items-top w-full px-3" },
              [
                !!_vm.item.image && !!_vm.item.image[0]
                  ? _c(
                      "div",
                      {
                        staticClass: "hidden md:flex flex-col h-full w-2/5 mr-5"
                      },
                      [
                        _c(
                          "a",
                          {
                            staticClass:
                              "block relative object-fit-fallback aspect-ratio-box aspect-ratio-box--8-5",
                            attrs: { href: _vm.item.url, title: _vm.item.title }
                          },
                          [
                            _c("img", {
                              staticClass:
                                "absolute lazyload w-full h-full block object-cover",
                              attrs: {
                                src:
                                  !!_vm.item.image && !!_vm.item.image[0]
                                    ? _vm.item.image[0].url
                                    : "/assets/img/placeholder.jpg",
                                alt:
                                  !!_vm.item.image && !!_vm.item.image[0]
                                    ? _vm.item.title
                                    : "",
                                "data-sizes": "auto",
                                onerror:
                                  "this.src = '/assets/img/placeholder.jpg';"
                              }
                            })
                          ]
                        )
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c("div", { staticClass: "w-full flex flex-col pr-3" }, [
                  _c("div", { staticClass: "mb-1" }, [
                    !!_vm.sectionTitle
                      ? _c(
                          "span",
                          {
                            staticClass:
                              "font-small text-xs text-primary-text pb-2",
                            staticStyle: { "margin-right": "-4px" }
                          },
                          [_vm._v(_vm._s(_vm.sectionTitle))]
                        )
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _c(
                    "a",
                    { attrs: { href: _vm.item.url, title: _vm.item.title } },
                    [
                      _c(
                        "h3",
                        {
                          staticClass:
                            "font-apercu font-normal text-2xl leading-tight pb-2 lg:hover:text-action transition-normal-text text-primary-text"
                        },
                        [
                          _vm._v(
                            "\n                " +
                              _vm._s(
                                !!_vm.item.title && _vm.item.title.length > 117
                                  ? _vm.item.title.slice(0, 114) + "..."
                                  : _vm.item.title
                              ) +
                              "\n              "
                          )
                        ]
                      )
                    ]
                  ),
                  _vm._v(" "),
                  !!_vm.item.categories ||
                  (!!_vm.sectionTitle && _vm.sectionId === 5)
                    ? _c(
                        "div",
                        { staticClass: "mt-auto" },
                        [
                          _vm.sectionId === 5
                            ? _c("span", { staticClass: "text-xs" }, [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(
                                      _vm._f("unixToIso")(_vm.item.postDate)
                                    ) +
                                    "\n                "
                                ),
                                _vm.item.categories.length
                                  ? _c(
                                      "span",
                                      { staticClass: "text-action mx-1" },
                                      [_vm._v("|")]
                                    )
                                  : _vm._e()
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          !!_vm.item.categories
                            ? _vm._l(_vm.item.categories.slice(0, 2), function(
                                cat,
                                key
                              ) {
                                return _c(
                                  "span",
                                  { key: key, staticClass: "text-xs" },
                                  [
                                    _vm._v(_vm._s(cat.title)),
                                    key <
                                    _vm.item.categories.slice(0, 2).length - 1
                                      ? _c(
                                          "span",
                                          { staticClass: "inline-block pr-1" },
                                          [_vm._v(",")]
                                        )
                                      : _vm._e()
                                  ]
                                )
                              })
                            : _vm._e()
                        ],
                        2
                      )
                    : _vm._e()
                ])
              ]
            )
          ])
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }