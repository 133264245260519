<template>
  <div>
    <div class="relative">
      <input
        type="text"
        name="officeSearch"
        class="rounded-none border-none focus:border-none shadow-md mb-10 placeholder:text-sm placeholder:text-primary-text h-16 pl-16 pr-12"
        autocomplete="off"
        :placeholder="placeholder"
        @input="handleInput($event)"
        v-model="search"
      >
      <button class="border-transparent pl-6 flex items-center absolute pin-t pin-l h-16 inline-block text-blue" type="submit" value="Submit">
        <svg width="18px" height="19px" viewBox="0 0 18 19" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
          <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g transform="translate(-1293.000000, -65.000000)" fill-rule="nonzero">
              <g>
                <g transform="translate(0.000000, 29.000000)">
                  <g transform="translate(1290.874369, 33.374369)">
                    <g transform="translate(12.500000, 13.000000) rotate(-315.000000) translate(-12.500000, -13.000000) translate(2.000000, 6.000000)">
                      <ellipse stroke="#2D314C" stroke-width="1.1" cx="7.18421053" cy="7" rx="7.18421053" ry="7"></ellipse>
                      <rect fill="#2D314C" transform="translate(17.561404, 7.538462) rotate(-360.000000) translate(-17.561404, -7.538462) " x="14.3684211" y="7" width="6.38596491" height="1.07692308"></rect>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </button>
      <button
        v-if="!!search"
        @click="search = ''"
        class="border-transparent pr-6 flex items-center absolute pin-t pin-r h-16 inline-block text-blue"
      >
        <svg width="10px" height="10px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
          <g id="mobile" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="iPhone-8-Copy-5" transform="translate(-26.000000, -26.000000)" fill="#2d314c" fill-rule="nonzero">
              <polygon id="" points="46 28.0274914 38.0274914 36 46 43.9725086 43.9725086 46 36 38.0274914 28.0274914 46 26 43.9725086 33.9725086 36 26 28.0274914 28.0274914 26 36 33.9725086 43.9725086 26"></polygon>
            </g>
          </g>
        </svg>
      </button>
    </div>

    <ul class="tabs closable list-reset border-t border-grey" :key="this.search">
      <li v-for="(location, id, index) in sortedLocations" class="border-b border-grey" >
        <a :href="'#' + location.id" class="pt-4-5 pb-4 pr-4 text-lg text-primary-text flex justify-between group" :title="location.title">
          <span>{{ location.title }}</span>
          <span v-html="arrow" class="arrow transition-transform"></span>
        </a>
        <div :id="location.id" class="py-3">
          <div v-for="office in location.offices" v-bind:key="office.title">
            <p class="text-base leading-normal pb-6">{{ office.title }}</p>
            <div class="mb-3">
              <div class="wysiwyg pr-8" v-html="office.description"></div>
              <p class="text-sm text-grey-link leading-normal lg:hover:text-action transition-normal-text">{{ office.address }}</p>
              <div>
                <a class="text-sm leading-normal text-grey-link lg:hover:text-action transition-normal-text">{{ office.phoneNumber }}</a>
              </div>
              <div v-if="!!office.faxNumber">
                <a class="text-sm leading-normal text-grey-link lg:hover:text-action transition-normal-text">Fax: {{ office.faxNumber }}</a>
              </div>
              <div>
                <a class="text-sm leading-normal text-grey-link lg:hover:text-action transition-normal-text">{{ office.email }}</a>
              </div>
              <div>
                <a :href="office.website" class="text-sm leading-normal text-action lg:hover:text-primary-text transition-normal-text" :title="office.website">{{ office.website }}</a>
              </div>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import tabs from '../js/components/tabs'

const arrow =
`<svg style='transform: rotate(90deg);' width="15px" height="10px" viewBox="0 0 5 10" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g fill-rule="evenodd">
        <g fill="#0066FF">
          <path d="M4.3203125,5.25 L1.05859375,1.8515625 C0.954426562,1.74739531 0.954426562,1.64322969 1.05859375,1.5390625 L1.64453125,0.953125 C1.76171934,0.848957813 1.86588496,0.842447461 1.95703125,0.93359375 L5.94140625,5.09375 C5.98046895,5.1328127 6,5.18489551 6,5.25 C6,5.31510449 5.98046895,5.3671873 5.94140625,5.40625 L1.95703125,9.56640625 C1.85286406,9.67057344 1.74869844,9.67057344 1.64453125,9.56640625 L1.05859375,8.9609375 C0.954426562,8.85677031 0.954426562,8.75260469 1.05859375,8.6484375 L4.3203125,5.25 Z"></path>
        </g>
    </g>
</svg>`

import { getQueryParam } from '../../src/js/components/searchCommon';

export default {
  props: {
    placeholder: {
      type: String,
      defult: '',
    },

    offices: {
      type: String,
      defult: '',
    }
  },

  data () {
    return {
      search: '',
      arrow: arrow,
    }
  },

  updated () {
    tabs()
  },
  created() {
    var query = getQueryParam('officeSearch');
    if (!!query) {
      this.search = query;
    }
  },

  computed: {
    filteredLocations () {
      const locations = JSON.parse(this.offices)
      return locations.filter(location => {
        const offices = location.offices.filter(office => {
          const searchTerms = location.title + ' ' + office.title + ' ' + office.address
          return searchTerms.toUpperCase().includes(this.search.toUpperCase())
        })
        return offices.length
      })
    },
    sortedLocations () {
      const locations = this.filteredLocations;
      return locations.sort(function(a, b){
        if(a.title < b.title) { return -1; }
        if(a.title > b.title) { return 1; }
        return 0;
      })
    }
  },

  methods: {
    handleInput ({target}) {
      this.search = target.value;
    }
  }
}
</script>
