// Information needed for connecting to our CraftQL endpoint
export const apiToken = window.graphQLApiKey || '';
export const apiUrl = '/api';

// Configure the api endpoint
export function configureApi (url, token) {
    return {
        baseURL: url,
        headers: {
            'Authorization': `Bearer ${token}`,
            'X-Requested-With': 'XMLHttpRequest'
        }
    };
};

// Execute a GraphQL query by sending an XHR to our api endpoint
export function executeQuery (api, query, variables, callback) {
    api.post('', {
        query: query,
        variables: variables
    }).then((result) => {
        if (callback) {
            callback(result.data);
        }
    }).catch((error) => {
    })
};

var query = window.location.search.substring(1);
export function getQueryParam(param) {
  if (query.length === 0) {
    return false;
  }
  var params = decodeURIComponent(query).split('&');
  for (var i = 0; i < params.length; i++) {
    var pair = params[i].split('=');
    if (decodeURIComponent(pair[0]) === param) {
      return decodeURIComponent(pair[1]);
    }
  }
  return false;
}
