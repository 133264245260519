class PrimaryNavigation {
  constructor() {
    this.dropdown = null;
    this.levelTwoMenus = document.querySelectorAll('.product-dropdown .menu-level-2.has-children');

    this.levelTwoMenus.forEach(element => {
      element.addEventListener('click', (event) => {
        event.stopImmediatePropagation();

        const target = event.target;

        const targetParent = target.closest('.product-dropdown')

        const allLevelTwoMenus = targetParent.querySelectorAll('.menu-level-2');
        const allSubmenus = targetParent.querySelectorAll('.list-level-3');

        if (allLevelTwoMenus) {
          allLevelTwoMenus.forEach(element => {
            element.classList.remove('active');
          });
        };

        if (allSubmenus) {
          allSubmenus.forEach(element => {
            element.classList.remove('active');
          });
        };

        if (target.dataset.levelTwoId) {
          const subMenu = document.querySelector(`[data-level-three-parent-id="${target.dataset.levelTwoId}"]`);
          if (subMenu) {
            subMenu.classList.add('active');
            target.classList.add('active');
          }
        }
      })
    });
  }

  showDropDown({ target }) {
    // Try setting the target as the dropdown.
    this.dropdown = target.closest('[data-dropdown]');

    // Set dropdown from from target id if no dropdown yet exists.
    if (!this.dropdown) {
      this.dropdown = document.querySelector('[data-dropdown="' + target.dataset.dropdownTarget + '"]');
    }

    // Toggle dropdowns if dropdown is set.
    if (this.dropdown) {
      this.toggleDropDowns();
    }
  }

  hideDropDown({ toElement }) {
    // Try setting the dropdown.
    // No data-dropdown attributes means the dropdown is no longer hovered.
    this.dropdown = toElement.closest('[data-dropdown-target], [data-dropdown]');

    // Delay hiding the dropdowns.
    setTimeout(this.toggleDropDowns.bind(this), 1000);
  }

  toggleDropDowns() {
    document.querySelectorAll('[data-dropdown]').forEach(dropdown => {
      if (dropdown !== this.dropdown) {
        dropdown.classList.add('hidden');
      }
      else {
        dropdown.classList.remove('hidden');
      }
    });
  }

}

document.body.addEventListener('click', (event) => {
  if (!event.target.closest('.product-dropdown') && !event.target.closest('.product-dropdown-button')) {
    // Close all dropdowns
    document.querySelectorAll('.product-dropdown').forEach(dropdown => {
      dropdown.style.display = 'none';
    });
  }
});

document.querySelectorAll('.product-dropdown-button').forEach(button => {
  button.addEventListener('click', () => {
    const dropdownId = button.getAttribute('data-dropdown-target');
    const dropdown = document.querySelector(`[data-dropdown="${dropdownId}"]`);

    if (dropdown) {
      // Check if the dropdown is already displayed
      const isDisplayed = dropdown.style.display === 'block';

      // Close all dropdowns
      document.querySelectorAll('.product-dropdown').forEach(dropdown => {
        dropdown.style.display = 'none';
      });

      // Toggle the display of the clicked dropdown
      dropdown.style.display = isDisplayed ? 'none' : 'block';
    }
  });
});



export default function () {
  new PrimaryNavigation();
}
