class Tabs {
  constructor(el) {
    this.hash = window.location.hash;
    history.scrollRestoration = 'manual';
    this.closable = el.classList.contains('closable');
    this.mobileNav = el.querySelectorAll('select')[0];
    this.anchors = el.querySelectorAll('a[href^="#"]');
    this.anchors.forEach(anchor => {
      anchor.addEventListener('click', ev => this.handleClick(ev));
    });
    // Removed the automatic navigation to the first tab
    // Only toggle if there's a valid hash in the URL
    if (!!this.hash) {
      const targetAnchor = el.querySelectorAll(`a[href="${this.hash}"]`)[0];
      if (targetAnchor) {
        this.toggleTargets(targetAnchor, true);
      }
    }
    if (!!this.mobileNav) {
      this.mobileNav.addEventListener('change', ev => this.handleClick(ev, true));
    }
  }

  toggleTargets(el, initiate) {
    this.anchors.forEach(anchor => {
      const element = document.querySelector(anchor.hash);
      if (el == anchor) {
        if (anchor.classList.contains('active') && this.closable) {
          element.style.display = 'none';
          anchor.classList.remove('active');
          if (!!anchor.querySelector('.arrow')) {
            anchor.querySelector('.arrow').classList.remove('transform-upside-down')
          }
        } else {
          if (element) element.style.display = '';
          history.replaceState(null, null, anchor.hash);
          anchor.classList.add('active');
          if (!!anchor.querySelector('.arrow')) {
            anchor.querySelector('.arrow').classList.add('transform-upside-down')
          }
          if (!!this.mobileNav) {
            this.mobileNav.value = anchor.hash;
          }
        }
      } else {
        if (element) element.style.display = 'none';
        anchor.classList.remove('active');
        if (!!anchor.querySelector('.arrow')) {
          anchor.querySelector('.arrow').classList.remove('transform-upside-down')
        }
      }
    });
  }

  handleClick(ev, isSelect) {
    ev.preventDefault();
    const target = isSelect ?
      document.querySelectorAll(`a[href="${ev.target.options[ev.target.selectedIndex].value}"]`)[0]
      : ev.target.closest('a[href^="#"]');
    if (target !== null) {
      this.toggleTargets(target);
    }
  }
}

export default function (selector = '.tabs') {
  document.querySelectorAll(selector).forEach(element => {
    new Tabs(element);
  });
}
