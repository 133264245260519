// init Swiper:

if (document.querySelector('.swiper')) {
  const swiper = new Swiper('.swiper', {
    // Optional parameter
    loop: true,
    autoplay: {
      delay: 5000,
      disableOnInteraction: false,
    },

    // If we need pagination
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
  });
}