<template>
  <transition name="fadeIn" mode="in-out">
    <li class="mb-4 pb-4 flex flex-wrap flex-col xxl:flex-row border-b border-1 border-solid border-grey-light" v-show="!isLoading">
      <div class="w-full">
        <div class="flex flex-col lg:flex-row -mx-3">
          <div class="flex flex-row lg:items-top w-full px-3">
            <div class="hidden md:flex flex-col h-full w-2/5 mr-5" v-if="!!item.image && !!item.image[0]">
              <a class="block relative object-fit-fallback aspect-ratio-box aspect-ratio-box--8-5" :href="item.url" :title="item.title">
                <img
                  class="absolute lazyload w-full h-full block object-cover"
                  :src="!!item.image && !!item.image[0] ? item.image[0].url : '/assets/img/placeholder.jpg'"
                  :alt="!!item.image && !!item.image[0] ? item.title : ''"
                  data-sizes="auto"
                  onerror="this.src = '/assets/img/placeholder.jpg';"
                >
              </a>
            </div>
            <div class="w-full flex flex-col pr-3">
              <div class="mb-1">
                <span v-if="!!sectionTitle" class="font-small text-xs text-primary-text pb-2" style="margin-right: -4px;">{{ sectionTitle }}</span>
              </div>
              <a class="" :href="item.url" :title="item.title">
                <h3 class="font-apercu font-normal text-2xl leading-tight pb-2 lg:hover:text-action transition-normal-text text-primary-text">
                  {{!!item.title && item.title.length > 117 ? item.title.slice(0, 114) + '...' : item.title}}
                </h3>
              </a>
              <div v-if="!!item.categories || (!!sectionTitle && sectionId === 5)" class="mt-auto">
                <span v-if="sectionId === 5" class="text-xs">
                  {{ item.postDate | unixToIso }}
                  <span v-if="item.categories.length" class="text-action mx-1">|</span>
                </span>
                <template
                  v-if="!!item.categories"
                >
                  <span 
                    v-for="(cat, key) in item.categories.slice(0, 2)"
                    :key="key"
                    class="text-xs">{{ cat.title }}<span v-if="key < item.categories.slice(0, 2).length - 1" class="inline-block pr-1">,</span></span>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </li>
  </transition>
</template>

<script>
import Vue from 'vue';
import moment from 'moment';

// moment.locale('sv');

export default {
  name: 'SearchPageItem',
  props: {
    item: {
      type: Object
    },
    isLoading: {
      type: Boolean
    },
    sections: {
      type: Array,
      default: []
    }
  },
  filters: {
    unixToIso: function(unix_timestamp) {
      return moment(unix_timestamp).format('DD MMM YYYY');
    }
  },
  methods: {
    formatDate: function(date, format) {
      const momentDate = moment.unix(date);

      switch (format) {
        case 'time':
          return momentDate.format('HH:mm');
        break;
        case 'day':
          return momentDate.format('dddd');
        break;
        case 'date':
          return momentDate.format('DD.MM');
        break;
      }
    }
  },
  computed: {
      sectionTitle() {
        let title = '';
        const section = this.sections.find(({id}) => id === this.item.sectionId);
        if (section) title = section.title;
        return title;
      },
      sectionId() {
        let id = null;
        const section = this.sections.find(({id}) => id === this.item.sectionId);
        if (section) id = section.id;
        return id;
      }
    }
};
</script>
