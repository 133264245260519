import Vue from 'vue';
import OfficeSearch from '../../vue/OfficeSearch';

if(document.querySelector('#office-search')) {
  const officeSearch = new Vue({
    el: '#office-search',
  
    components: {
      OfficeSearch
    }
  })
  
}