if (document.getElementsByClassName("modal")[0]) {
  var modalEl = document.getElementsByClassName("modal")[0];
  var closeModalButtons = document.getElementsByClassName("modal__close");
  var closeExtraButtons = document.getElementsByClassName("modal__close--button");

  var setCookie = function (name, value, expiryDays) {
    var d = new Date();
    d.setTime(d.getTime() + expiryDays * 24 * 60 * 60 * 1000);
    var expires = 'expires=' + d.toUTCString();
    document.cookie = name + '=' + value + ';' + expires + ';path=/;SameSite=Lax';
  };

  var getCookie = function (name) {
    var cookieName = name + '=';
    var decodedCookie = decodeURIComponent(document.cookie);
    var cookies = decodedCookie.split(';');
    for (var i = 0; i < cookies.length; i++) {
      var c = cookies[i].trim();
      if (c.indexOf(cookieName) === 0) {
        return c.substring(cookieName.length, c.length);
      }
    }
    return null;
  };

  var showModal = function () {
    if (modalEl) {
      modalEl.classList.remove("hidden");
    }
  };

  var closeModal = function () {
    if (modalEl) {
      modalEl.classList.add("hidden");
    }
  };

  // Show the modal if the cookie is not already set
  if (!getCookie("modalShown")) {
    setTimeout(function () {
      showModal();
      setCookie("modalShown", "true", 30); // Set cookie for 30 days
    }, 1000); // Delay showing the modal by 1 second
  }

  // Close modal logic
  window.addEventListener("click", function (e) {
    if (
      (modalEl && e.target === modalEl) ||
      Array.prototype.some.call(closeModalButtons, function (btn) {
        return btn === e.target;
      }) ||
      Array.prototype.some.call(closeExtraButtons, function (btn) {
        return btn === e.target;
      })
    ) {
      closeModal();
    }
  });
}
