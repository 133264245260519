import Vue from 'vue'
import axios from 'axios'
import _ from 'lodash'
import transFilter from 'vue-trans';

import { configureApi, apiToken, apiUrl, getQueryParam, executeQuery } from './searchCommon';
import CategorySearchMeta from '../../vue/CategorySearchMeta';
import SearchPageList from '../../vue/SearchPageList';
import Pagination from '../../vue/Pagination';

Vue.use(transFilter);

// Site search application
function getcategorySearchLandingData() {
  return {
    sectionType: '',
    searchApi: axios.create(configureApi(apiUrl, apiToken)),
    searchQuery: '',
    searchResults: [],
    searchSorting: 'postdate desc',
    meta: {
      totalCount: 0,
      pages: [],
      hasNext: false,
      hasPrev: false,
      nextPageUrl: '',
      prevPageUrl: '',
      elementsPerPage: 24,
      currentPage: 1
    },
    isLoading: true,
    isReady: false,
    site: _.get(window, 'craftData.site', 'global'),
    sections: window.sections
  };
}

var filterChangeTimer;

var generalSearch = function () {

  // What to search for
  const searchEntries = ['careers_career_Entry', 'news_article_Entry', 'pages_page_Entry', 'products_product_Entry'];
  let searchQueries = ''
  _.each(searchEntries, (entryType) => {
    searchQueries = searchQueries +
      `
      ... on ${entryType} {
        id
        title
        sectionId
        url${entryType === 'news_article_Entry' ? `
          image {
            url
          }
          newsCategory {
            title
          }
        ` : entryType === 'products_product_Entry' ? `
          productImage {
            url
          }
          productCategory {
            title
          }
        ` : entryType === 'pages_page_Entry' ? `
          image {
            url
          }
        ` : ''}
        postDate
      }`
  });

  // The query to search for entries in Craft
  const searchQuery =
    `
  query searchQuery($site: [String], $needle: String, $limit: Int, $offset: Int, $orderBy: String)
    {
      entries( site: $site limit: $limit offset: $offset orderBy: $orderBy search: $needle) {
        ${searchQueries}
      }
      entryCount(limit: $limit search: $needle offset: $offset)
    }
  `;

  new Vue({
    el: document.getElementById('searchPage'),
    delimiters: ['<%', '%>'],
    // Here we can register any values or collections that hold data
    data: getcategorySearchLandingData(),
    components: {
      SearchPageList,
      CategorySearchMeta,
      Pagination,
    },
    beforeCreate: function () {
    },
    created: function () {
      const searchParam = getQueryParam('q');
      const categoryParam = getQueryParam('cat');
      const categoryIdParam = getQueryParam('cat-id');
      const pageParam = getQueryParam('page');

      if (!!searchParam) {
        this.searchQuery = searchParam;
      }
      if (!!categoryParam) {
        this.searchCategory.slug = categoryParam;
      }
      if (!!categoryIdParam) {
        this.searchCategory.id = categoryIdParam;
      }
      if (!!pageParam) {
        this.meta.currentPage = parseInt(pageParam);
      }
    },
    mounted: function () {
      this.performSearch();
    },
    updated: function () {
    },
    destroyed: function () {
    },
    watch: {
      'meta.currentPage': function (val) {
        this.scrollup();
        this.meta.offset = this.meta.elementsPerPage * (val - 1);
        this.performSearch();
      },
      searchQuery: function (val, oldVal) {
        if (!!oldVal && val !== oldVal) {
          this.meta = _.get(getcategorySearchLandingData(), 'meta');
        }
        this.performSearch();
      },
      searchSorting: function () {
        this.scrollup();
        this.performSearch();
      },
    },
    filters: {
    },
    computed: {
    },
    methods: {
      sortSearch(data) {
        const entries = data;
        if (!!entries) {
          _.forEach(entries, function (entry) {
            const category = _.get(entry, 'newsCategory') || _.get(entry, 'productCategory');
            if (!!category) {
              entry.categories = category;
            }
          })
        }
      },
      performSearch() {
        var self = this;

        if (self.searchQuery === '') {
          self.searchResults = [];
          self.isSearchDropdownOpen = false;
          return true;
        }

        self.isLoading = true;
        self.setHistory();
        const searchTitle = !!self.searchQuery ? `title:*${self.searchQuery}*` : '';
        const searchCategory = !!self.searchQuery && `categories:"${self.searchQuery}" OR categories::"${self.searchQuery}"`;
        const searchTag = !!self.searchTag && `tags:${self.searchQuery} OR tags::${self.searchQuery}`;

        const searchString = _.compact([searchTitle, searchCategory, searchTag]).join(' OR ');

        // Set the variables we will pass in to our query
        const variables = {
          site: self.site,
          needle: searchString,
          limit: self.meta.elementsPerPage || 24,
          offset: self.meta.offset || 0,
          orderBy: self.searchSorting,
        };
        // Execute the query
        clearTimeout(filterChangeTimer);

        filterChangeTimer = setTimeout(function () {
          executeQuery(self.searchApi, searchQuery, variables, (data) => {
            const dataPath = data.data;
            self.searchResults = dataPath.entries;
            self.meta.totalCount = dataPath.entryCount;
            self.meta.totalPages = Math.ceil(Number(dataPath.entryCount) / (self.meta.elementsPerPage || 24));;

            self.sortSearch(dataPath.entries);

            if (!self.isReady) {
              self.isReady = true;
            }
            self.isLoading = false;
          });
        }, 500);
      },
      setHistory: function () {
        var self = this;
        var paramString = '';
        if (!!self.searchQuery) {
          paramString += '?q=' + self.searchQuery;
        }
        if (!!_.get(self.searchCategory, 'slug')) {
          paramString += !!paramString ? ('&cat=' + self.searchCategory.slug) : (paramString += '?cat=' + self.searchCategory.slug);
          paramString += !!paramString ? ('&cat-id=' + self.searchCategory.id) : (paramString += '?cat-id=' + self.searchCategory.id);
        }
        if (!!self.meta.currentPage) {
          paramString += !!paramString ? ('&page=' + self.meta.currentPage) : (paramString += '?page=' + self.meta.currentPage);
        }
        if (window.history && window.history.replaceState) {
          var pageUrl =
            location.protocol + '//' + location.host + location.pathname;
          var url = pageUrl + paramString;
          history.replaceState(null, null, url);
        }
      },
      scrollup: function () {
        var top = 0;

        setTimeout(function () {
          window.scrollTo(top, 0);
        }, 100);
        return false;
      },
    },
  });
};

!!document.getElementById('searchPage') && generalSearch();
